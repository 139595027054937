<template>
  <div class="container">
    <div class="page-title">客服列表</div>
    <div style="display: flex;margin-left: 20px;margin-top: 10px;">
      <el-button :disabled="!accessConfig.add" type="primary" icon="el-icon-folder-add" @click="create">添加</el-button>
    </div>
    <div class="content">
      <template>
        <el-table
          :data="tableData"
          border
          style="width:100%;flex:1;overflow:auto;"
          v-loading="listLoading"
        >
          <el-table-column
            prop="username"
            label="账户名"
            width="160">
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="160">
          </el-table-column>
          <el-table-column
            prop="wechat"
            label="微信"
            width="160">
          </el-table-column>
          <el-table-column
            prop="qq"
            label="qq"
            width="160">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机号"
            width="160">
          </el-table-column>
          <el-table-column
            label="岗位（全部）"
            width="160">
            <template slot-scope="scope">
<!--              <div style="display: flex;align-items: center;">-->
<!--                <div style="width: 45px;" v-if="scope.row.role === 2">管理员</div>-->
<!--                <div style="width: 45px;" v-if="scope.row.role === 3">客服</div>-->
<!--                <div style="width: 45px;" v-if="scope.row.role === 4">跟单</div>-->
<!--                <div style="width: 45px;" v-if="scope.row.role === 5">跟单</div>-->
<!--              </div>-->
              <div style="display: flex;align-items: center;">
                 <div style="width: 100px;">{{scope.row.roleName}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="使用状态（全部）"
            width="160">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;">
                <div style="width: 45px;">{{scope.row.status === 2 ? '使用中' : '停用'}} </div>
                <el-button :disabled="!accessConfig.update" @click="handleClick2(scope.row)" :type="scope.row.status === 2 ? 'danger' : 'primary'" size="small">{{scope.row.status === 2 ? '停用': '启用' }}</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 10, 20, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      :total="total"
    ></el-pagination>
    <el-dialog title="添加" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="40%">
      <div>
        <div class="date-box">
          <div class="label" style="width: 80px;">账户名:</div>
          <el-input
            placeholder="请输入内容"
            v-model="username"
            clearable>
          </el-input>
        </div>
       <!-- <div class="date-box">
          <div class="label" style="width: 80px;">姓名:</div>
          <el-input
            placeholder="请输入内容"
            v-model="name"
            clearable>
          </el-input>
        </div>-->
        <div class="date-box">
          <div class="label" style="width: 80px;">微信:</div>
          <el-input
            placeholder="请输入内容"
            v-model="wechat"
            clearable>
          </el-input>
        </div>
        <div class="date-box">
          <div class="label" style="width: 80px;">qq:</div>
          <el-input
            placeholder="请输入内容"
            v-model="qq"
            clearable>
          </el-input>
        </div>
        <div class="date-box">
          <div class="label" style="width: 80px;">手机号:</div>
          <el-input
            placeholder="请输入内容"
            v-model="phone"
            clearable>
          </el-input>
        </div>
        <div class="date-box">
          <div class="label" style="width: 66px;">岗位:</div>
          <el-select v-model="role" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;justify-content: center;">
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tableData: [],
        ifSuccNotice: false,
        textarea: '',
        orderNumber: '',
        checkboxGroup: [],
        activeName: 'second',
        options: [],
        username: '',
        wechat: '',
        qq: '',
        phone: '',
        role:'', //岗位
        value: '',
        dialogTableVisible: false,
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        accessConfig: {}
      };
    },
    activated () {
      const functionCodeList = window.sessionStorage.functionCodeList;
      this.accessConfig = {
        add: functionCodeList.includes('use_menu_service_add'),
        update: functionCodeList.includes('use_menu_service_update'),
      }
      this.queryData(this.currentPage,this.pageSize);
    },
    methods: {
      queryData(currentPage,pageSize){
        this.listLoading = true;
        this.$http.post(`/user/findManagerByPage/${currentPage}/${pageSize}`, {  }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryData(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryData(this.currentPage,this.pageSize);
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleClick2(row) {
        console.log('row========',row);
        const url = row.status === 2 ? `/user/userRefuse/${row.id}` : `/user/userpass/${row.id}`
        this.$http.post(url, {
          id: row.id
        }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            // this.shopOptions = res.data;
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.queryData(this.currentPage,this.pageSize);
              // this.tableData = res.data.data.list;
              // this.$router.push({ path: "/layout" });
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      create(){
        console.log('提交');
        if(this.options && this.options.length > 0){
          this.dialogTableVisible = true;
        }else{
          this.queryOptions();
        }
      },
      queryOptions(){
        this.$http.post("/user/findRole", {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.options = res.data.data;
              this.dialogTableVisible = true;
            } else {
              this.$message.error(res.data.data)
            }
          });

      },
      confirm(){
        this.$http.post("/user/saveManager", {
          username: this.username,
          wechat: this.wechat,
          qq: this.qq,
          phone: this.phone,
          role: this.role,
          password: '123456'
        }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.dialogTableVisible = false;
              this.$message({
                showClose: true,
                message: res.data.data,
                type: 'success'
              });
              this.username = '';
              this.wechat = '';
              this.qq = '';
              this.phone = '';
              this.role = '';
              this.queryData(this.currentPage,this.pageSize);
            } else {
              this.$message.error(res.data.data)
            }
          });
      }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
    margin-bottom: 20px;
  }
  .content {
    /*display: flex;*/
    /*flex-direction: column;*/
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    margin-top: 10px;
    .first-line {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /*text-align: right;*/
      font-size: 10px;
      color: #000000;
      padding-right: 10px;
      min-width: 60px;
    }

  }
</style>
